<template>
  <main>
    <v-card class="overflow-hidden">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Nombre desarmonía</span>
            <v-text-field
              v-model="desarmonia.name"
              :error-messages="validationErrors.name"
              solo
              dense
              placeholder="Escriba el nombre"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-center mt-2 mb-2">
      <v-btn small class="mx-2" color="primary" rounded @click="save()">
        Guardar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </div>
  </main>
</template>

<script>
import { storeDisharmony, updateDisharmony } from "@/services/humansRights";
import sweetalert from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import getEventStateColor from "../../../utils/eventStateColors";

export default {
  props: {
    mode: {
      type: String,
      default: "create",
    },
    isFemale: {
      type: Boolean,
      default: false,
    },
  },
  name: "newDisharmony.vue",
  data() {
    return {
      menu2: false,
      timeMenu: false,
      municipalities: [],
      territories: [],
      departments: [],
      sidewalks: [],
      zone: [],
      search: "",
      territoryTypes: [],
      disharmonyTypes: [],
      desarmonia: {
        name: "",
        id: "",
      },
      eventId: "",
    };
  },
  validations() {
    return {
      desarmonia: {
        name: { required },
      },
    };
  },
  methods: {
    async save() {
      this.$v.desarmonia.$touch();
      if (this.$v.desarmonia.$invalid) return;
      try {
        this.showLoader();

        const eventRequestData = {
          name: this.desarmonia.name,
          is_female : this.isFemale
        };

        const storeEventResponse =
          this.mode === "edit"
            ? await updateDisharmony(this.desarmonia.id, eventRequestData)
            : await storeDisharmony(eventRequestData);

        this.eventId = storeEventResponse.data.event_id;

        await sweetalert.fire(
          "Registro guardado correctamente",
          "",
          "success"
        );

        this.hideLoader();

        this.$router.push({
          path : `/humansRights/value-references/${this.isFemale}`
        });

      } catch (err) {
        this.hideLoader();
        console.error(err);
        sweetalert.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    setEventData(eventData) {
      this.desarmonia = eventData;
    },
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
  },
  async created() {},
  computed: {
    validationErrors() {
      return {
        name: [
          ...[
            !this.$v.desarmonia.name.required ? "este campo es requerido" : "",
          ].filter((x) => x != ""),
        ],
      };
    },
    ...mapState(["dataSource", "user"]),
  },
};
</script>

<style scoped></style>
