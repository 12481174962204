<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
            <v-icon>mdi-chevron-double-right</v-icon> LISTA DESARMONÍAS
            <v-icon>mdi-chevron-double-right</v-icon> Crear desarmonías
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>
    <create :is-female="isFemale"></create>
  </v-container>
</template>

<script>
import create from "./disharmony/Create.vue";

export default {
  props : {
    isFemale: {
      type: Boolean,
      default: false,
    },
  },
  name: "newDisharmony.vue",
  components: { create }
};
</script>

<style scoped></style>
